import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import paymentSuccess from "../../Assets/PaymentSuccess.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import dupageTicket from "../../Assets/dupageTicket.svg";
import sentenceCase from "../../utils/utilities";
import { GoDotFill } from "react-icons/go";
import { MdOutlineFileDownload, MdOutlineLocationOn } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import mainpost from "../../Assets/mainpost.png";
import Qrcode from "../../Assets/Qrcode.png";
import pass from "../../Assets/localpass.png";
import cross from "../../Assets/Divider.png";
import moment from "moment";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
export default function PaymentSuccess() {
  const [sessionId, setSessionId] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const token = useSelector((state) => state.user.token);
  const isDupage = useSelector((state) => state.user.dupageCounty);
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    setSessionId(sessionId);
    const getOrderDetails = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        session_id: sessionId,
        sys_datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        order_type: state ? state.orderType : 0,
      };
      await axios
        .post(`${devApi}/api/e/paymentSucessGuest`, body, config)
        .then((response) => {
          setOrderDetails(response.data);
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    getOrderDetails();
  }, []);
  if (!orderDetails) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {orderDetails.customer ? (
        <div className="container">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center  mt-4">
              <div className="title-payment text-center">
                {" "}
                You have successfully booked your tickets!
              </div>
              {/* <p className="mt-5">
                Thanks for your order{" "}
                <strong>{orderDetails.customer.first_name}</strong>
              </p> */}
              <p className="success-para text-center">
                Your event tickets with a QR code will be sent to{" "}
                <strong>{orderDetails.customer.email} </strong>or you can{" "}
                <a
                  className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  href={orderDetails.orders[0].ticketfullpath}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download your Ticket
                </a>{" "}
                here.
              </p>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 payment-box mb-4">
                <div className="event-order-box pb-0">
                  {/* <div className="d-flex flex-column justify-content-between align-items-center w-100">
                  <h5>Order Summary</h5>
                  <div className="d-flex justify-content-between align-items-center total-count t bg-success-subtle border p-2 success-border rounded-6">
                    <h5 className="ext-light-emphasis">
                      {orderDetails.amountdetails.total_ticket_quantity}
                    </h5>
                    <h6 className="ext-light-emphasis mx-2">Tickets Booked</h6>
                  </div>
                </div> */}
                  <div className="d-flex justify-content-between  align-items-center mb-2">
                    <h5 className="confirmed-h5">
                      {" "}
                      <FaRegCheckCircle size={20} color="#1EA133" /> Booking
                      Confirmed
                    </h5>
                    <div className="d-flex justify-content-center flex-column align-items-center total-count">
                      <h5>
                        {
                          orderDetails.orders[0].amountdetails
                            .total_ticket_quantity
                        }
                      </h5>
                      <h6>Tickets</h6>
                    </div>
                  </div>
                  {/* <div className="under-ticket d-flex flex-column justify-content-between align-items-center">
                  {orderDetails.eventimagefullpath ? (
                    <img src={orderDetails.eventimagefullpath} alt="" />
                  ) : (
                    <></>
                  )}

                  <div className="under-ticket1 text-center justify-content-center align-items-center mt-4">
                    <h6>{sentenceCase(orderDetails.events.title)}</h6>
                    <p>
                      <strong>{orderDetails.events.start_date} </strong>
                    </p>
                    <p>
                      <MdOutlineLocationOn
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "#024DDF",
                        }}
                      />{" "}
                      {sentenceCase(orderDetails.events.venue_name)}
                    </p>
                  </div>
                </div> */}
                  <div className="under-ticket d-flex ">
                    <img
                      src={
                        orderDetails?.orders[0].event_ext.event_image_fullpath
                          ? orderDetails.orders[0].event_ext
                              .event_image_fullpath
                          : mainpost
                      }
                      alt=""
                      className="payment-img"
                      style={{ borderRadius: "10px" }}
                    />
                    <div className="under-ticket1 ms-4">
                      <h6>{orderDetails.orders[0].event.title}</h6>
                      <p>
                        Date & Time :{" "}
                        <b>
                          {orderDetails.orders[0].event.startday},{" "}
                          {orderDetails.orders[0].event.starttime}{" "}
                        </b>
                      </p>
                      <p>
                        Venue :{" "}
                        <b>
                          {orderDetails.orders[0].event.venue_name},
                          {orderDetails.orders[0].event.location_address_line_1}
                          ,
                          {orderDetails.orders[0].event.location_address_line_2}
                          - {orderDetails.orders[0].event.location_post_code}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#f5f5f5", height: "35px" }}>
                  <img
                    src={cross}
                    alt=""
                    style={{ width: "-webkit-fill-available" }}
                  />
                </div>
                <div className="event-order-box">
                  {orderDetails.orders[0].order_items?.map(
                    (ticketType, index) => (
                      <>
                        <div
                          id={index}
                          className="d-flex justify-content-between align-items-center ticketcharge "
                        >
                          <p>
                            {ticketType.title} x {ticketType.quantity}
                          </p>
                          <p>
                            {" "}
                            ${" "}
                            {(
                              ticketType.unit_price * ticketType.quantity
                            ).toFixed(2)}
                          </p>
                        </div>
                      </>
                    )
                  )}

                  <div className="d-flex justify-content-between align-items-center processcharge">
                    <p>
                      Processing fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.processing_fee
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center processcharge ">
                    <p>
                      Platform fee
                      <small className="text-primary ms-1">
                        (non-refundable)
                      </small>
                    </p>

                    <p>
                      {" "}
                      $
                      {parseFloat(
                        orderDetails.orders[0].amountdetails.platform_fee
                      ).toFixed(2)}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between align-items-center processcharge mt-2">
                    <small className="amountpay-sucess ">Total amount</small>
                    <small className="amountpay-sucess ">
                      ${" "}
                      {orderDetails.orders[0].amountdetails.full_total.toFixed(
                        2
                      )}
                    </small>
                  </div>
                </div>
                <div className="event-order-box my-2">
                  <div className="d-flex justify-content-between">
                    <div className="order-data">
                      <h5>Booking Date & Time: </h5>
                      <h6>
                        {moment(orderDetails.orders[0].system_datetime).format(
                          "ddd DD, MMMM YYYY hh:mm A"
                        )}
                      </h6>
                    </div>
                    <div className="order-data">
                      <h5>Booking ID:</h5>
                      <h6>{orderDetails.orders[0].order_reference}</h6>
                    </div>
                  </div>
                </div>
                <div className="qrcode-box text-center my-2">
                  <h6>
                    If you have any questions, email us at{" "}
                    <a href="mailto:info@eventgem.com">info@eventgem.com</a>
                  </h6>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-4 mt-4">
                  <a
                    href={orderDetails.orders[0].ticketfullpath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="ticket-download-btn">
                      {" "}
                      <MdOutlineFileDownload size={22} className="me-3" />
                      Download Ticket
                    </button>
                  </a>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center">
                  <button className="proceedbtn">
                    {" "}
                    <Link to={`/userhome/orderHistory`}>My Orders</Link>
                  </button>
                </div>*/}
              </div>
              {/* <img
                alt=".."
                className="img-fluid"
                style={{ width: "550px", height: "300px" }}
                src={paymentSuccess}
              /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center ndf">
          <div className="loader-box">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </>
  );
}
