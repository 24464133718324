import React, { useEffect, useRef, useState } from "react";

// Styles
import "./css/QrScanner.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../Assets/qr-frame.svg";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import userPhoto from "../../Assets/userPhoto.png";
import { LuTicket } from "react-icons/lu";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { FaRegCircleStop } from "react-icons/fa6";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// "#D9D9D9"
// "#024DDF"
const DMDeviceCamera = () => {
  // QR States
  const scanner = useRef();
  const videoEl = useRef();
  const qrBoxEl = useRef();
  const [qrOn, setQrOn] = useState(true);
  const [showTicketCountLoad, setShowTicketCountLoad] = useState(false);
  const [showScannerLoad, setScannerLoad] = useState(true);
  const [showCloseEntriesLoad, setCloseEntriesLoad] = useState(false);
  const [videoElCurrent, setVideoElCurrent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [attendeeData, setAttendeeData] = useState();
  const [message, setMessage] = useState();
  const [result, setResult] = useState(false);
  const [closeEntryTrigger, setCloseEntryTrigger] = useState(false);
  const [ticketScannedData, setTicketScannedData] = useState();
  const [dmDashboardData, setDmDashboard] = useState();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const doorManagerLink = useSelector((state) => state.user.doorManagerLink);
  const [dmLogout, setDmLogout] = useState(false);
  const location = useLocation();
  // Result
  const [scannedResult, setScannedResult] = useState("");
  const [alreadyScannedData, setAlreadyScannedData] = useState();
  const [timeStamp, setTimeStamp] = useState(false);
  const [color, setColor] = useState("");
  const navigate = useNavigate();
  const getDmDashboard = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/doorManagerDashboard`, null, config)
      .then((response) => {
        if (response.data.success) {
          setDmDashboard(response.data);
        } else {
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (attendeeData?.ticket) {
      switch (attendeeData?.ticket.id) {
        case 47:
          setColor("#df6d02");
          break;
        case 48:
          setColor("#d43832");
          break;
        case 49:
          setColor("#024DDF");
          break;
        case 50:
          setColor("#e7c649");
          break;
        case 51:
          setColor("#116704");
          break;
        default:
          setColor("024DDF");
          break;
      }
    }
  }, [attendeeData]);
  // Success
  const onScanSuccess = async (result) => {
    scanner.current.stop();
    setAlreadyScannedData();
    setTimeStamp(false);
    setScannedResult(result?.data);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      qr_code: result.data,
    };

    await axios
      .post(`${devApi}/api/dm/scanAttendeeQR`, body, config)
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.door_manager.logout ||
            response.data.door_manager.admin_entry_closed
          ) {
            scanner.current.stop();
            setDmLogout(true);
            setAlreadyScannedData();
            setTimeStamp(false);
            setMessage();
            setShowModal(false);
            setResult(false);
            const intervalCall = setTimeout(() => {
              navigate(`/doormanagement${doorManagerLink}`);
            }, 4000);
            return () => {
              // clean up
              clearTimeout(intervalCall);
            };
          } else {
            setAttendeeData(response.data);
            setMessage();
            setShowModal(true);
            setResult(true);
          }
        } else {
          if (response.data.attendee) {
            setAlreadyScannedData(response.data);
            setTimeStamp(true);
            setResult(false);
            setShowModal(true);
            setMessage();
          } else {
            setAttendeeData();
            setAlreadyScannedData();
            setResult(false);
            setShowModal(true);
            setMessage(response.data.message);
          }

          scanner.current.stop();
        }
      })
      .catch((error) => {});
  };

  // Fail
  const onScanFail = (err) => {};
  const scan = () => {
    scanner.current.start();
    setShowModal(false);
  };
  // const detectDevice = async () => {
  //   navigator.bluetooth
  //     .requestDevice({
  //       filters: [{ name: "BarCode Scanner BLE" }],
  //       services: ["0000180f-0000-1000-8000-00805f9b34fb"], // Required to access service later.
  //     })
  //     .then((device) => {
  //       console.log("device", device);
  //       return device.gatt?.connect();
  //     })
  //     .then((server) => {
  //       console.log("server", server);
  //       return server.getPrimaryService("0000180f-0000-1000-8000-00805f9b34fb");
  //     })
  //     .then((service) => {
  //       console.log("sevice", service);
  //       return service.getCharacteristic(
  //         "0000180f-0000-1000-8000-00805f9b34fb-0x1200e3bb4a"
  //       );
  //     })
  //     .then((characteristic) => {
  //       // Reading Battery Level…
  //       return characteristic.readValue();
  //     })
  //     .then((value) => {
  //       console.log(`QR ${value.getUint8(0)}`);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const getTicketCount = async () => {
    setShowTicketCountLoad(true);
    setScannerLoad(false);
    setCloseEntriesLoad(false);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/doorManagerDashboard`, null, config)
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.door_manager.logout ||
            response.data.door_manager.admin_entry_closed
          ) {
            scanner.current.stop();
            setDmLogout(true);
            setShowTicketCountLoad(false);
            setMessage();
            setShowModal(false);
            setResult(false);
            const intervalCall = setTimeout(() => {
              navigate(`/doormanagement${doorManagerLink}`);
            }, 4000);
            return () => {
              // clean up
              clearTimeout(intervalCall);
            };
          } else {
            setShowTicketCountLoad(true);
            setTicketScannedData(response.data);
          }
        } else {
          setShowTicketCountLoad(false);
          setScannerLoad(true);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (videoEl?.current && showScannerLoad) {
      setVideoElCurrent(true);
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    } else {
      setVideoElCurrent(false);
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoElCurrent) {
        scanner?.current?.stop();
      }
    };
  }, [showScannerLoad]);
  useEffect(() => {
    getDmDashboard().then(() => {
      if (location.state && location.state.entry_closed) {
        setCloseEntryTrigger(true);
      }
    });
  }, []);

  const getbackToScanner = () => {
    setScannerLoad(true);
    setCloseEntriesLoad(false);
    setShowTicketCountLoad(false);
  };
  const handleCloseEntries = () => {
    setScannerLoad(false);
    setCloseEntriesLoad(true);
    setShowTicketCountLoad(false);
  };
  const handleTicketCountHide = () => {
    setShowTicketCountLoad(false);
    setScannerLoad(true);
  };
  const closeEntriesForDM = async () => {
    setCloseEntriesLoad(false);
    getDmDashboard();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/entryClose`, null, config)
      .then((response) => {
        if (response.data.success) {
          setVideoElCurrent(false);
          scanner.current.stop();
          setScannerLoad(false);
          setShowTicketCountLoad(false);
          setCloseEntryTrigger(true);
        } else {
          setCloseEntryTrigger(true);
        }
      })
      .catch((error) => {});
    //getTicketCount();
  };
  const handleScanAgain = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/entryOpen`, null, config)
      .then((response) => {
        if (response.data.success) {
          setScannerLoad(true);
          setCloseEntryTrigger(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  const handleEntryClosed = () => {
    setCloseEntriesLoad(false);
    setScannerLoad(true);
  };
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader d-flex flex-column align-items-center justify-content-center">
      <div className="navbar fixed-top navbar-light bg-light justify-content-between">
        <div className="m-2 mx-lg-4">
          <img
            src={eventgemLogo}
            alt="Company Logo"
            className="img-fluid "
            fetchpriority="high"
          />
        </div>
      </div>
      {/* QR */}
      <div className="d-flex flex-column align-items-center justify-content-center">
        {showTicketCountLoad ? (
          <div></div>
        ) : closeEntryTrigger ? (
          <>
            {dmDashboardData ? (
              <>
                <div className="data-back d-flex align-items-center justify-content-center my-2">
                  <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                    <div className="my-2">
                      <h5 className="ticket-scan-head">Tickets Status</h5>
                    </div>
                    <div className="my-2">
                      <small>Total Tickets Scanned</small>
                    </div>
                    <div className="my-2">
                      <h5 className="scan-total">
                        {dmDashboardData.scancount}
                      </h5>
                    </div>
                  </div>
                </div>
                <h5 className="my-3 entry-close-text">Entries Closed</h5>
              </>
            ) : (
              ""
            )}
          </>
        ) : dmLogout ? (
          <div className="data-back d-flex align-items-center justify-content-center my-2">
            <div className="d-flex flex-column align-items-center justify-content-center mx-2">
              <div className="my-2">
                <h5 className="ticket-scan-head">Entries Stopped</h5>
              </div>
              <div className="my-2 text-center">
                <small>
                  Your account has been deactivated by Admin. Logging out.
                </small>
              </div>
              <div className="my-2 text-center">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {" "}
            <video
              ref={videoEl}
              key={showScannerLoad}
              className="video-box"
            ></video>
            <div
              ref={qrBoxEl}
              className="qr-box align-items-center justify-content-center"
            >
              <img
                src={QrFrame}
                alt="Qr Frame"
                width={256}
                height={256}
                className="qr-frame"
              />
            </div>
          </React.Fragment>
        )}
      </div>

      {closeEntryTrigger ? (
        <div>
          <button className="btn btn-primary" onClick={handleScanAgain}>
            {" "}
            Continue Scanning
          </button>
        </div>
      ) : (
        <div className="navbar fixed-bottom navbar-light bg-light  justify-content-around ">
          <div className="d-flex flex-column align-items-center justify-content-center mx-2">
            <LuTicket
              size={24}
              color={showTicketCountLoad ? "#024DDF" : ""}
              onClick={getTicketCount}
            />
            <h5 className="option-text">Ticket Status</h5>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center mx-2">
            <MdOutlineQrCodeScanner
              size={24}
              color={showScannerLoad ? "#024DDF" : ""}
              onClick={getbackToScanner}
            />
            <h5 className="option-text">Scanner</h5>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center mx-2">
            <FaRegCircleStop
              size={24}
              color={showCloseEntriesLoad ? "#024DDF" : ""}
              onClick={handleCloseEntries}
            />
            <h5 className="option-text">Stop Entries</h5>
          </div>
        </div>
      )}

      {showModal ? (
        <div className="d-flex justify-content-between  flex-column align-items-center mb-2 ">
          <div className="overlayScan">
            <div classNmae="matter">
              {result ? (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-4 result-header">
                    <div className="d-flex  justify-items-center align-items-center ">
                      <LuTicket size={25} color={color} />

                      <h5
                        className="successTicketType ms-2"
                        style={{ color: color }}
                      >
                        {attendeeData.ticket.title}
                      </h5>
                      {parseInt(attendeeData.ticket.price) == 0 ? (
                        <small className="ms-2 free-text">(Free)</small>
                      ) : (
                        <small
                          className="ms-2 title-desc"
                          style={{ color: color }}
                        >
                          {attendeeData.ticket.description}
                        </small>
                      )}
                    </div>
                    <button
                      className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                      style={{ borderRadius: 20, minWidth: "30%" }}
                      onClick={scan}
                    >
                      <small className="resultButtons">Scan Next</small>
                      <IoIosArrowForward />
                    </button>
                  </div>
                  <div className="d-flex  flex-column mx-4 mt-4">
                    <div className="d-flex justify-content-between flex-row align-items-center my-2">
                      <small className="attendee-ticket">
                        <strong>
                          Time :
                          {moment(attendeeData?.attendee.arrival_time).format(
                            "hh:mm A"
                          )}
                        </strong>
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <LuTicket size={20} color={"#024DDF"} />

                        <h5 className="attendee-ticket ms-2">
                          {attendeeData.ticket.title}
                        </h5>
                      </div>

                      <div className="d-flex justify-content-between text-center align-items-center">
                        <div className="d-flex justify-content-between text-center align-items-center">
                          <h6 className="ticket-id">
                            Ticket Id : {attendeeData?.attendee.ticket_id}
                          </h6>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex  justify-content-center align-items-center mt-5">
                      <FaRegCheckCircle size={24} color="#1EA133" />{" "}
                      <h5 className="successScan ms-2">Success</h5>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2 result-header">
                  <div className="d-flex  justify-items-center align-items-center ">
                    <RxCrossCircled size={24} color="#FF4C32" />{" "}
                    <h5 className="failedScan ms-2">
                      {alreadyScannedData && timeStamp
                        ? "Already Scanned"
                        : "Failed"}
                    </h5>
                  </div>
                  <button
                    className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                    style={{ borderRadius: 20 }}
                    onClick={scan}
                  >
                    <small className="resultButtons">Try Again</small>
                  </button>
                </div>
              )}
              {message && (
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <small className="text ml-2">{message}</small>
                </div>
              )}
              {alreadyScannedData && timeStamp && (
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <small className="text ml-2">
                    {alreadyScannedData?.message}
                  </small>
                  <small className="">
                    <strong>
                      On{" "}
                      {moment(alreadyScannedData?.attendee.arrival_time).format(
                        "Do MMM, YYYY hh:mm A"
                      )}
                    </strong>
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showTicketCountLoad ? (
        <Modal
          show={showTicketCountLoad}
          onHide={handleTicketCountHide}
          centered
        >
          <Modal.Body>
            {ticketScannedData ? (
              <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                <div className="my-2">
                  <h5 className="ticket-scan-head">Tickets Status</h5>
                </div>
                <div className="my-2">
                  <small>Total Tickets Scanned</small>
                </div>
                <div className="my-2">
                  <h5 className="scan-total">{ticketScannedData.scancount}</h5>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                <div className="loader-box">
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <div></div>
      )}
      {showCloseEntriesLoad ? (
        <Modal show={showCloseEntriesLoad} onHide={handleEntryClosed} centered>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center justify-content-center mx-2">
              <div className="d-flex flex-row my-2">
                <small>Are you sure you want to stop entries?</small>
              </div>
              <div className="d-flex flex-row my-2 justify-content-between">
                <button
                  className="btn btn-secondary mx-2"
                  onClick={handleEntryClosed}
                >
                  No
                </button>
                <button
                  className="btn btn-primary mx-2"
                  onClick={closeEntriesForDM}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DMDeviceCamera;
